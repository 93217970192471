.navtab {
    width: 370px;
    display: flex;
    list-style: none;
    justify-content: space-between;
    align-items: center;
}

.navtab__link {
    text-decoration: none;
    color: #526B4B;
    font-size: 15px;
    font-weight: 600;
}

@media screen and (max-width: 630px) {
    .navtab {
        width: 100%;
        flex-direction: column;
    }

    .navtab__item {
        padding-bottom: 20px;
    }
}