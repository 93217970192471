.input {
    width: 350px;
    background-color: #fff;
    border: 1px solid rgba(80, 80, 80, .4);
    border-radius: 30px;
    padding: 15px 10px;
    text-align: center;
    margin-bottom: 5px;
}

@media screen and (max-width: 609px) {
    .input {
        width: 280px;
    }
}

@media screen and (max-width: 383px) {
    .input {
        width: 250px;
    }
}