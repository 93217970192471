.header {
    width: 100%;
    height: 80px;
    background-color: #FBF8EF;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header__logo {
    width: 50px;
    height: 50px;
    background-image: url(../../image/service-line.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    margin-left: 20px;
    margin-right: 35px;
}

.header__burger-btn {
    position: relative;
    display: none;
    height: 32px;
    width: 32px;
    right: 20px;
    cursor: pointer;
}

.header__burger-lines {
    height: 26px;
    width: 32px;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
}

.header__burger-line {
    display: none;
    height: 4px;
    width: 100%;
    border-radius: 10px;
    background-color: #526B4B;
}

@media screen and (max-width: 630px) {
    .header {
        height: 50px;
    }

    .header__navbar {
        display: none;
    }

    .header__social {
        display: none;
    }

    .header__burger-btn {
        display: block;
    }

    .header__burger-btn .header__burger-lines {
        display: flex;
    }

    .header__burger-btn .header__burger-lines .header__burger-line {
        display: block;
    }
}