.section__title {
    font-size: 35px;
    font-weight: 500;
    line-height: 27px;
    padding-bottom: 30px;
    margin-bottom: 0;
}

@media screen and (max-width: 626px) {
    .section__title {
        font-size: 18px;
        line-height: 22px;
        padding-bottom: 28px;
    }
}

@media screen and (max-width: 400px) {
    .section__title {
        padding-bottom: 15px;
    }
}
