.diploma {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 0;
    padding: 50px 0;
    color: #526B4B;
}

.diploma__content {
    display: flex;
    flex-direction: column;
}