.tooltip {
    font-family: "Inter", "Arial", sans-serif;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s linear;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip_opened {
    visibility: visible;
    opacity: 1;
}

.tooltip__container {
    width: 300px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FBF8EF;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.15);
}

.tooltip__reg-status {
    color: #000;
    font-weight: 600;
    font-size: 20px;
    line-height: 12px;
}

.tooltip__close-button {
    width: 32px;
    height: 32px;
    background-image: url(../../../image/close-.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: rgba(0, 0, 0, 0);
    border: none;
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0px;
    padding: 0;
    transition: 0.5s;
}