.diploma-card {
    border: 1px solid rgba(80, 80, 80, .4);
    border-radius: 10px;
    padding: 10px;
    margin: 10px 0;
}

.diploma-card__img {
    width: 100%;
    height: 350px;
}