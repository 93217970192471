.promo {
    width: 100%;
    font-family: Georgia, 'Times New Roman', Times, serif;
    background-color: #526B4B;
    /* background: linear-gradient(to right, #FBF8EF 53%, #526B4B 47%); */
    color: #FBF8EF;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}

.promo__content {
    display: grid;
    grid-template-columns: minmax(292px, 500px) 550px;
    align-items: center;
    column-gap: 20px;
    margin: 60px 0;
}

.promo__img {
    width: 100%;
    height: 550px;
    object-fit: cover;
}

.promo__title {
    font-size: 75px;
    font-weight: 600;
    line-height: 60px;
    padding: 5px 0 45px 0;
}

.promo__subtitle {
    font-size: 25px;
    font-weight: 600;
}

.promo__btn {
    width: 150px;
    height: 50px;
    background: transparent;
    color: #FBF8EF;
    border: 1.25px solid #FBF8EF;
    border-radius: 3px;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

@media screen and (max-width: 1167px) {
    .promo__content {
        grid-template-columns: minmax(292px, 500px) 400px;
    }

    .promo__img {
        height: 400px;
    }

    .promo__title {
        font-size: 65px;
        font-weight: 600;
        line-height: 60px;
        padding: 5px 0 35px 0;
    }
}

@media screen and (max-width: 941px) {
    .promo__content {
        grid-template-columns: minmax(292px, 500px) 350px;
    }

    .promo__img {
        height: 350px;
    }

    .promo__title {
        font-size: 55px;
        font-weight: 600;
        line-height: 60px;
        padding: 5px 0 35px 0;
    }
}

@media screen and (max-width: 887px) {
    .promo__content {
        grid-template-columns: minmax(292px, 500px) 300px;
        margin: 40px 10px;
    }

    .promo__img {
        height: 300px;
    }

    .promo__title {
        font-size: 45px;
        line-height: 45px;
    }
}

@media screen and (max-width: 629px) {
    /* .promo {
        background: linear-gradient(to top, #526B4B 61%, #FBF8EF 32%);
    } */
    .promo__content {
        grid-template-columns: minmax(292px, 400px);
        row-gap: 25px;
        margin: 30px 10px;
        text-align: center;
    }

    .promo__img {
        height: 400px;
    }

    .promo__title {
        font-size: 45px;
        line-height: 45px;
    }
}