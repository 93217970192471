.services {
    width: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #526B4B;
    color: #FBF8EF;
}

.services__content {
    margin: 0 200px;
}

@media screen and (max-width: 1069px) {
    .services__content {
        margin: 0 100px;
    }
}

@media screen and (max-width: 827px) {
    .services__content {
        margin: 0 50px;
    }
}

@media screen and (max-width: 693px) {
    .services__content {
        margin: 0 10px;
    }
}