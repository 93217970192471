.faq {
    width: 100%;
    padding: 50px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FBF8EF;
    color: #526B4B;
    scroll-snap-align: start;
}

.faq__content {
    display: grid;
    grid-template-columns: 350px minmax(250px, 600px);
    column-gap: 20px;
    padding: 0 10px;
}

.faq__question {
    text-align: justify;
    font-size: 13px;
    font-weight: 400;
    line-height: 13px;
    padding-top: 5px;
}

.faq__img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    grid-area: 1 / 1 / 2 / 2;
    border-radius: 20px;
}

.faq_accordion {
    grid-area: 1 / 2 / 3 / 3;
}

@media screen and (max-width: 649px) {
    .faq__content {
        grid-template-columns: minmax(300px, 600px);
        grid-template-rows: repeat(4, min-content);
        row-gap: 20px;
        padding: 10px;
    }

    .faq_accordion {
        grid-area: 2 / 1 / 3 / 2;
    }
}