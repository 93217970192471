.register-block {
    width: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #526B4B;
    color: #FBF8EF;
}

.register-block__card {
    background-image: url(../../image/micah-hallahan-K2mqEMFsf9k-unsplash.jpg);
    background-size: cover;
    width: calc(100% - 50px);
    height: 500px;
    border-radius: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.register-block__title {
    font-size: 50px;
    font-weight: 600;
    text-align: center;
}

.register-block__form {
    display: flex;
    flex-direction: column;
    padding-top: 15px;
}

.register-block__label {
    font-size: 12px;
    font-weight: 600;
    padding-bottom: 5px;
    margin-left: 20px;
}

.register-block__error {
    display: none;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    color: #FF0000;
    margin: 5px 0 7px;
    opacity: 0;
}

.register-block__error_active {
    display: block;
    opacity: 1;
}

.register-block__btn {
    margin-top: 20px;
    background-color: #526B4B;
    border: 1px solid rgba(80, 80, 80, .4);
    border-radius: 30px;
    padding: 15px 10px;
    color: #FBF8EF;
    cursor: pointer;
}

.register-block__btn_disable {
    background-color: gray;
}

@media screen and (max-width: 609px) {
    .register-block__title {
        font-size: 40px;
    }

    .register-block__input {
        width: 280px;
    }
}

@media screen and (max-width: 383px) {
    .register-block__card {
        width: calc(100% - 20px);
    }

    .register-block__title {
        font-size: 40px;
    }

    .register-block__input {
        width: 250px;
    }
}