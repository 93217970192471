.hamburger-menu {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    justify-content: end;
    visibility: hidden;
    background-color: rgba(0, 0, 0, .4);
    transition: all .45s ease;
    opacity: 0;
    z-index: 1;
}

.hamburger-menu_active {
    opacity: 1;
    visibility: visible;
}

.hamburger-menu__container {
    position: relative;
    top: 0;
    right: 0;
    width: 400px;
    height: 100vh;
    background-color: #FBF8EF;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hamburger-menu__navtab {
    margin-top: 50px;
}

.hamburger-menu__cls-btn {
    position: absolute;
    height: 32px;
    width: 32px;
    top: 10px;
    right: 20px;
    cursor: pointer;
}

.hamburger-menu__cross-lines {
    height: 26px;
    width: 32px;
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
}

.hamburger-menu__cross-line {
    min-height: 4px;
    width: 100%;
    border-radius: 10px;
    background-color: #526B4B;
}

.hamburger-menu__cross-line_first {
    transform-origin: 0% 0%;
    transform: rotate(45deg);
}

.hamburger-menu__cross-line_second {
    transform-origin: 0% 100%;
    transform: rotate(-45deg);
}

@media screen and (max-width: 401px) {
    .hamburger-menu__container {
        width: 320px;
    }
}
