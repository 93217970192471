.reviews {
    width: 100%;
    padding: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #FBF8EF;
    color: #526B4B;
}

.reviews__card-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 300px);
    grid-template-rows: repeat(3, min-content);
    column-gap: 70px;
}

.reviews__card:nth-child(2) {
    grid-area: 2 / 2 / 3 / 3;
}

.reviews__card:nth-child(3) {
    grid-area: 3 / 1 / 4 / 2;
}

.reviews__card {
    border: 1px solid rgba(80, 80, 80, .4);
    border-radius: 10px;
    padding: 10px;
}

.reviews__title {
    font-size: 16px;
    font-weight: 600;
    padding-bottom: 5px;
}

.reviews__text {
    font-size: 14px;
    font-weight: 400;
}

@media screen and (max-width: 709px) {
    .reviews__card-wrapper {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, min-content);
        column-gap: 0;
        row-gap: 20px;
        margin: 15px;
    }

    .reviews__card:nth-child(2) {
        grid-area: 2 / 1 / 3 / 2;
    }
}