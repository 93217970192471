.about {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #526B4B;
    padding: 50px 0;
    color: #FBF8EF;
}

.about__wrapper {
    display: grid;
    grid-template-columns: repeat(2, minmax(292px, 350px));
    column-gap: 15px;
    padding: 10px;
}

.about__text {
    font-size: 15px;
    background-color: #FBF8EF;
    color: #526B4B;
    padding: 10px;
    border-radius: 10px;
}

.about__img {
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 10px;
}

@media screen and (max-width: 621px) {
    .about__wrapper {
        display: grid;
        grid-template-columns: repeat(1, minmax(292px, 500px));
        grid-template-rows: min-content;
        column-gap: 0;
        row-gap: 15px;
        padding: 10px;
    }

    .about__img {
        width: 100%;
        height: 350px;
        object-fit: cover;
        border-radius: 10px;
    }
}