.social-links {
    display: flex;
    list-style: none;
}

.social-links__item {
    width: 30px;
    height: 30px;
}

.social-links__img {
    width: 30px;
    height: 30px;
}

/* @media screen and (max-width: 630px) {
    .social-links {
        margin-bottom: 100px;
    }
} */