@import url(./vendor/normalize.css);
@import url(./vendor/fonts/font-face.css);

* {
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}
  