.info-topic {
    width: 100%;
    padding: 100px 0;
    background-color: #FBF8EF;
    display: flex;
    justify-content: center;
    font-family: 'Helvetica', 'Times New Roman', Times, serif;
    color: #526B4B;
}

.info-topic__list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, minmax(200px, 300px));
    justify-content: center;
    align-items: center;
    column-gap: 70px;
    row-gap: 30px;
    padding: 0 10px;
}

.info-topic__item {
    width: 100%;
    text-align: center;
    border-bottom: 2px solid #526B4B;
    padding-bottom: 15px;
}

.info-topic__item:first-child {
    border: none;
    box-shadow: none;
}

.info-topic__title {
    font-size: 32px;
    font-weight: 500;
    margin: 0;
}

.info-topic__text {
    font-size: 20px;
    font-weight: 500;
}

.info-topic__item_main {
    grid-area: 1 / 2 / 2 / 3;
}

.info-topic__item_third {
    grid-area: 2 / 2 / 3 / 3;
}

@media screen and (max-width: 1172px) {
    .info-topic__title {
        font-size: 28px;
    }

    .info-topic__text {
        font-size: 18px;
    }
}

@media screen and (max-width: 813px) {
    .info-topic__list {
        column-gap: 20px;
        row-gap: 10px;
    }

    .info-topic__title {
        font-size: 25px;
    }

    .info-topic__text {
        font-size: 15px;
    }
}

@media screen and (max-width: 667px) {
    .info-topic {
        padding: 60px 0;
    }

    .info-topic__item {
        padding-bottom: 8px;
    }

    .info-topic__list {
        grid-template-columns: repeat(3, minmax(70px, 200px));
        column-gap: 15px;
        row-gap: 5px;
    }

    .info-topic__title {
        font-size: 17px;
    }

    .info-topic__text {
        font-size: 13px;
    }
}

@media screen and (max-width: 400px) {
    .info-topic__list {
        column-gap: 15px;
        row-gap: 5px;
    }

    .info-topic__title {
        font-size: 14px;
    }

    .info-topic__text {
        font-size: 10px;
    }
}